import recon from "@/plugin/recon";
import {nanoid} from "nanoid";

export default {
    namespaced: true, actions: {
        async get_material_data(context) {
            const list = JSON.parse((await recon.get('/Api/getMediaList')).data.data)
            context.commit('PREPARE_MATERIAL', list)
        }
    }, mutations: {
        PREPARE_MATERIAL(state, value) {
            state.material = []
            for (const i of value) {
                state.material.push({
                    classid: nanoid(32), icon_url: i.path, name: i.medianame,level:0
                })
            }
        }
    }, state: {
        material: []
    }
}