import recon from '@/plugin/recon'

export default {
    namespaced: true,
    actions: {
        async get_goods(context) {
            context.commit('PREPARE_GOODS', JSON.parse((await recon.get('/Api/Goods/getGoodsList')).data.data))
        }
    },
    mutations: {
        PREPARE_GOODS(state, v) {
            v.forEach(val => {
                val.price = val.price.toString().split('.')
            })

            state.Goods = v
        }
    },
    state: {
        Goods: []
    }
}