<!--
 * @Date: 2022-02-07 12:44:05
 * @LastEditors: AiMuC
 * @LastEditTime: 2022-02-07 12:44:06
 * Email:aimu_c@qq.com
 * Github:https://github.com/AiMuC
-->
<template>

  <router-view></router-view>


</template>

<script>
import {mapActions} from "vuex";
import router from "@/router/router";

export default ({
  methods: {
    ...mapActions('footer', ['get_website_info']),
    ...mapActions('imgData', ['get_material_data']),
    ...mapActions('Goods', ['get_goods']),
  },

  created() {
    document.title = 'PUBG'

    this.get_website_info()
    this.get_material_data()
    this.get_goods()
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      router.replace('/reject-page')
      return
    }

    if (localStorage.getItem('isLogin') != null && localStorage.getItem('isLogin') != 0) {
      this.$axios.post('/user/RefreshAccessToken', {}, {
        headers: {
          'refresh-token': this.$store.getters.f5Token
        }
      }).then(value => {
        // console.log(value)
        this.$store.commit('setAcToken', JSON.parse(value.data.data).access_token)
        localStorage.setItem('token', JSON.parse(value.data.data).access_token)
        this.$store.commit('setIsLogin', parseInt(Math.random() * 8848) + 1)
        //  console.log(JSON.parse(value.data.data).access_token)
      }).catch(() => {
        this.$message.error('登录失效，请重新登陆')
        this.$store.commit("setF5Token", "");
        this.$store.commit("setUsrRole", "");
        this.$store.commit("setAcToken", "");
        this.$store.commit("setIsLogin", "0");
        this.$store.commit("setMail", "");
        setTimeout(() => {
          this.$router.replace('/')
        }, 2000)
      })
    }

  }
})
</script>

