import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import router from './router/router'
import store from './store/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import util from '@/plugin/utils'
import 'animate.css'
import recon from '@/plugin/recon'

Vue.use(ElementUI)
Vue.use(ViewUI)
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(Antd)
Vue.use(VueRouter)
Vue.use(util)


Vue.prototype.getNowFormatDate = function () {
    var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}


Vue.directive('tit', {
    bind(el) {
        el.style.color = '#' + Math.random().toString(16).slice(2, 8);
    }
})
Vue.filter('toUppercase', function (value) {
    return value.toUpperCase()
})
Vue.filter('snippet', function (value) {
    return value.slice(0, 100) + "..."
})


new Vue({
    render: h => h(App),
    router,
    store,
    beforeCreate() {
        Vue.prototype.$axios = recon
        Vue.prototype.$notice = (type, {msg, data}, key) => {
            this.$notification[type]({
                key,
                message: msg,
                description:
                data,
            });
        }
        Vue.prototype.$r = router
    }
}).$mount('#app')

