import recon from '@/plugin/recon'

export default {
    namespaced: true,
    actions: {
        async get_website_info(context) {
            context.state.website_info = JSON.parse((await recon.get('/Api/GetWebSiteInfo')).data.data)
        }
    },
    mutations: {},
    state: {
        website_info: {}
    }

}