export default {
    install(Vue) {


        Vue.prototype.$base64ToBlob = code => {
            let parts = code.split(';base64,')
            let contentType = parts[0].split(':')[1]
            let raw = atob(parts[1]);// 解码base64得到二进制字符串
            let rawLength = raw.length
            let uInt8Array = new Uint8Array(rawLength) // 创建8位无符号整数值的类型化数组
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i) // 数组接收二进制字符串
            }

            return new Blob([uInt8Array], {
                type: contentType
            })
        }

        Vue.prototype.$blobToFile = (r, v) => {
            r.lastModifiedDate = new Date();
            r.name = v;
            return new File([r], v, {type: r.type, lastModified: Date.now()});
        }


    }
}