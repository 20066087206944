import Vue from 'vue'
import Vuex from 'vuex'
import footer from './foreend/footer'
import imgData from "@/store/foreend/imgData";
import userInfo from "@/store/personality/userInfo"
import Goods from "@/store/personality/Goods";
import baoyue from '@/store/foreend/baoyue'
import createVuexAlong from 'vuex-along'

Vue.use(Vuex)

const actions = {}

const mutations = {
    setUserId: (state, userId) => {
        state.userId = userId,
            localStorage.setItem('userId', userId)
    },
    setBagData: (state, bagData) => {
        state.bagData = bagData,
            localStorage.setItem('bagData', bagData)
    },
    setSteamId: (state, steamId) => {
        state.steamId = steamId
        sessionStorage.setItem('steamId', steamId)
    },
    setBaseUrl: (state, baseUrl) => {
        state.baseUrl = baseUrl
        localStorage.setItem('baseUrl', baseUrl)
    },
    setAcToken: (state, AcToken) => {
        state.AcToken = AcToken
        localStorage.setItem('AcToken', AcToken)
    },
    setIsLogin: (state, isLogin) => {
        state.isLogin = isLogin
        localStorage.setItem('isLogin', isLogin)
    },
    setF5Token: (state, f5Token) => {
        state.f5Token = f5Token
        localStorage.setItem('f5Token', f5Token)
    },
    setMail: (state, Mail) => {
        state.Mail = Mail
        localStorage.setItem('Mail', Mail)
    },
    setUsrRole: (state, usrRole) => {
        state.usrRole = usrRole
        localStorage.setItem('usrRole', usrRole)
    }
}

const state = {
    userId: '' || localStorage.getItem('userId'),
    bagData: '' || localStorage.getItem('bagData'),
    steamId: '' || sessionStorage.getItem('steamId'),
    baseUrl: '' || localStorage.getItem('baseUrl'),
    AcToken: '' || localStorage.getItem('AcToken'),
    isLogin: '' || localStorage.getItem('isLogin'),
    f5Token: '' || localStorage.getItem('f5Token'),
    Mail: '' || localStorage.getItem('Mail'),
    usrRole: '' || localStorage.getItem('usrRole'),
}

const getters = {
    userId: (state) => state.userId,
    bagData: (state) => state.bagData,
    steamId: (state) => state.steamId,
    baseUrl: (state) => state.baseUrl,
    AcToken: (state) => state.AcToken,
    f5Token: (state) => state.f5Token,
    isLogin: (state) => state.isLogin,
    Mail: (state) => state.Mail,
    usrRole: (state) => state.usrRole
}

export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters,

    modules: {
        footer,
        imgData,
        userInfo,
        Goods,
        baoyue
    },

    plugins: [createVuexAlong({
        name: "pubg-information",
        session: {},
        local: {list: ['baoyue']}
    })]
})
