import recon from "axios";
import Vue from 'vue'

// recon.defaults.baseURL = 'http://localhost:8080/api'
// recon.defaults.baseURL = '/api'
recon.defaults.withCredentials = true
const key = '123'


recon.interceptors.request.use(t => {

    if (localStorage.getItem('AcToken') || localStorage.getItem('viptoken')) {
        if (Vue.prototype.$r.currentRoute.path.includes('/vip')) {
            t.headers["Access-Token"] = localStorage.getItem('viptoken')
        } else {
            t.headers["Access-Token"] = localStorage.getItem('AcToken')
        }
    }
    return t
}, error => Promise.reject(error))

recon.interceptors.response.use(t => {

    switch (t.data.code) {
        case -1:
            if (!t.data.msg.includes('steamId为17个数字,请检查后重新输入')) {
                Vue.prototype.$message.error({content: t.data.msg, key})
            }
            return Promise.reject(t.data)

        case 0:
            return Promise.reject(t.data)
    }


    return t
})


export default recon