import axios from '@/plugin/recon'

export default {
    namespaced: true,
    actions: {
        async get_user_info(context) {
            context.state.user_info = JSON.parse((await axios.post('/user/api/GetUserProfile')).data.data)
        }
    },
    mutations: {},
    state: {
        user_info: {}
    }
}