import VueRouter from 'vue-router'
import Vue from "vue";
import recon from '@/plugin/recon'

const router = new VueRouter({
//     mode:history,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../components/HomePage.vue'),
            redirect: '/index.html',
        },

        {
            path: '/index.html',
            component: () => import('../components/HomePage.vue'),
        },
        {
            path: '/login',
            component: () => import('../components/logUsr/LoginPage.vue'),
        },
        {
            path: '/getsteamid',
            component: () => import('../components/GetId.vue'),
        },
        {
            path: '/empty',
            component: () => import('../components/emptyPage.vue'),
        },
        {
            path: '/getsteamlibrary',
            component: () => import('../components/SteamLibrary.vue'),
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import('../components/help/HelpPrivacy.vue')
        },
        {
            path: '/HelpPc',
            component: () => import('../components/help/HelpPc.vue')
        },
        {
            path: '/reject-page',
            component: () => import('../pages/reject-page.vue')
        },
        {
            path: '/blogView/:id',
            name: 'blogView',
            component: () => import('../components/blog/blogView.vue')
        },
        {
            path: '/ArticleView',
            name: 'articleview',
            component: () => import('../components/article/ArticleView.vue')
        },
        {
            path: '/BackEnd',
            name: 'BackEnd',
            redirect: '/BackEnd/webInfo',
            component: () => import('../components/BackEnd.vue'),
            children: [
                {
                    path: '/BackEnd/webInfo',
                    name: '网站信息设置',
                    component: () => import('../components/webInfo/webInformation.vue')
                },
                {
                    path: '/BackEnd/AddArticle',
                    name: '发布文章',
                    component: () => import('../components/article/AddArticle.vue')
                },
                {
                    path: '/BackEnd/Guns',
                    name: '枪械排序设置',
                    component: () => import('../components/guns/Guns.vue')
                },
                {
                    path: '/BackEnd/Txz',
                    name: '通行证设置',
                    component: () => import('../components/guns/Txz.vue')
                },
                {
                    path: '/BackEnd/ItemPrice',
                    name: '物品价格设置',
                    component: () => import('../components/guns/ItemPrice.vue')
                },
                // {
                //         path: '/BackEnd/Guns/EditItem/:id',
                //     name: 'EditItem',
                //     component: () => import('../components/guns/EditItem.vue')
                // },
                {
                    path: '/BackEnd/article/ArticleList',
                    name: '文章列表',
                    component: () => import('../components/article/ArticleList.vue')
                },
                {
                    path: '/BackEnd/article/ArticleList/EditArticle/:id',
                    name: '编辑 文章',
                    component: () => import('../components/article/EditArticle.vue')
                },

                {
                    path: 'logger',
                    name: '日志',
                    component: () => import('@/pages/backend/logger')
                },
                {
                    path: 'customGuns',
                    name: '自定组合',
                    component: () => import('@/components/guns/CustomGuns')
                },
                {
                    path: 'HelperCenter',
                    name: '帮助中心/更新介绍',
                    component: () => import('@/pages/backend/helpOrUpdate/helpCenterList')
                },
                {
                    path: 'SendHelper',
                    name: '发送文章',
                    component: () => import('@/pages/backend/helpOrUpdate/addHelpOrUpdate')
                },
                {
                    path: 'EditHelper/:id',
                    name: '编辑文章',
                    props: true,
                    component: () => import('@/pages/backend/helpOrUpdate/EditorHelper')
                },
                {
                    path: 'userList',
                    name: '用户列表',
                    component: () => import('@/pages/backend/user_list/userlist')
                },
                {
                    path: 'AdviceList',
                    name: '建议列表',
                    component: () => import('@/pages/backend/web_advice/AdviceList')
                },
                {
                    path: 'Goods',
                    name: '商品列表',
                    component: () => import('@/pages/backend/product/Goods')
                },
                {
                    path: 'order',
                    name: '订单列表',
                    component: () => import('@/pages/backend/order/Order')
                },
                {
                    path: 'material',
                    name: '素材列表',
                    component: () => import('@/pages/backend/material/Material')
                },
                {
                    path: 'BlackList',
                    name: '黑名单列表',
                    component: () => import('@/pages/backend/blacklist/BlackList')
                },
                {
                    path: 'MonthlyUserList',
                    name: '包月用户列表',
                    component: () => import('@/pages/backend/user_list/vipuserlist')
                },
                {
                    path: 'GameLevel',
                    name: '游戏等级设置',
                    component: () => import('@/components/guns/gameLevel.vue')
                },
                {
                    path: 'HtmlRegex',
                    name: 'HTML生成',
                    component: () => import('@/pages/backend/HtmlRegex')
                }
            ]
        },
        {
            name: 'personality',
            path: '/personality',
            redirect: '/personality/home',
            component: () => import('@/pages/personality/Main'),
            children: [
                {
                    name: 'PersonHome',
                    path: 'home',
                    component: () => import('@/pages/personality/PersonHome')
                },
                {
                    name: 'payment',
                    path: 'payment',
                    component: () => import('@/pages/personality/Payment')
                },
                {
                    name: 'vacFinder',
                    path: 'vacFinder',
                    component: () => import('@/pages/personality/VacFinder')
                },
                {
                    name: 'blackList',
                    path: 'blackList',
                    component: () => import('@/pages/personality/MenuBlackList')
                },
                {
                    name: 'UseRecord',
                    path: 'UseRecord',
                    component: () => import('@/pages/personality/UseRecord')
                },
                {
                    name: 'question',
                    path: 'question',
                    component: () => import('@/pages/personality/Question')
                },
                {
                    name: 'ChangePwd',
                    path: 'ChangePwd',
                    component: () => import('@/pages/personality/ChangePwd')
                },
                {
                    name: 'imgCompress',
                    path: 'imgCompress',
                    component: () => import('@/pages/personality/ZipImg')
                },

            ]
        },
        {
            name: 'outSideBlackList',
            path: '/blackList',
            component: () => import('@/pages/personality/blackList')
        },

        {
            path: '/vip',
            redirect: '/vip/login',
        },
        {
            path: '/vip/login',
            component: () => import('@/pages/login.vue')
        },
        {
            path: '/vip/getLibrary',
            component: () => import('@/components/GetLibrary.vue')
        },
        {
            path: '/vip/library',
            component: () => import('@/components/Library.vue')
        },


        {
            path: '*',
            component: () => import('@/pages/404Page')
        }

    ]
})

router.afterEach((to) => {
    if (to.path.includes('/vip')) {
        if (localStorage.getItem('viptoken') != undefined) {
            recon.post('/vipuser/GetInventory', {
                "steamid": "1",
                "junk": 1
            }).then(v => {
                if (v.data.code == 600) {
                    localStorage.removeItem('viptoken')
                    Vue.prototype.$message.error({content: v.data.msg, duration: 1, key: '123'})
                    router.replace('/vip/login')
                } else {
                    if (to.path.includes('/vip/login')) {
                        Vue.prototype.$message.success({content: '已登录', duration: 1, key: '123'})
                        router.replace('/vip/getLibrary')
                    }
                }

            }, r => {
                if (r.data.code == 600) {
                    localStorage.removeItem('viptoken')
                    Vue.prototype.$message.success({content: r.data.msg, duration: 1, key: '123'})
                    router.replace('/vip/login')
                }

                if (to.path.includes('/vip/login')) {
                    Vue.prototype.$message.success({content: '已登录', duration: 1, key: '123'})
                    router.replace('/vip/getLibrary')
                }

            })
        } else {
            if (!to.path.includes('/login')) {
                Vue.prototype.$message.warning({content: '未登录', duration: 1, key: '123'})
            }
            router.replace('/vip/login')
        }

    }

})

export default router
