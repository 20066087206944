import Vue from "vue";
import axios from "@/plugin/recon";

export default {
    namespaced: true,
    actions: {
        async login(context, v) {
            axios.post('/vipuser/vipLogin', {
                ...v
            }).then(val => {
                val = val.data
                val.data=JSON.parse(val.data)

                context.state.user = v.user
                context.commit('INIT_USER', val)
            }, reason => {
                Vue.prototype.$notice('warning', reason, '123')
            })
        }
    },
    mutations: {
        INIT_USER(context, {data, msg}) {
            localStorage.setItem('viptoken', data.access_token)
            context.token = data.access_token
            context.user_role = data.user_role

            Vue.prototype.$message.success({content: msg, duration: 2})
            Vue.prototype.$r.push('/vip/getLibrary')
        }
    },
    state: {
        token: '',
        user: '',
        user_role: '',
    }
}